import axios, { AxiosError } from "axios";
import { router } from "../router/router";
import { routes } from "../constants/routes";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  timeout: 10000,
  withCredentials: true,
});

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error instanceof AxiosError) {
      // Unauthorized
      if (error.response?.status === 401) {
        router.navigate(routes.login);
      }
    }
    console.log(error);

    return Promise.reject(error);
  }
);
