import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import { router } from "./router/router";

import "./index.css";

const root = ReactDOM.createRoot(
  document.getElementById("dashboard") as HTMLElement
);


const defaultTheme = createTheme();

root.render(
  <ThemeProvider theme={defaultTheme}>
    <ToastContainer />
    <RouterProvider router={router} />
  </ThemeProvider>
);
