/**
 * Youtube parser link
 * @param url string
 * return embed link
 */
export const youtubeParserLink = (url: string) => {
  const id = url.split("/");

  return `https://www.youtube.com/embed/${id.pop()}`;
};
