import { string, object, InferType } from "yup";

export const mediaSchema = object({
  type: string().required(),
  url: string()
    .url("Введіть правильну URL-адресу YouTube")
    .matches(
      /^(https?\:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/,
      "Введіть правильну URL-адресу YouTube"
    ),
  picture: string(),
});

export type MediaSchema = InferType<typeof mediaSchema>;
