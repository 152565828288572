import { useEffect, useState } from "react";
import EventForm from "../../forms/event/event.form";
import { useParams } from "react-router-dom";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { axiosInstance } from "../../axios/instance";
import { apis } from "../../axios/apis";
import GlobalLoader from "../../components/GlobalLoader";
import { Event as EventType } from "../../types/Event";

const Event = () => {
  const params = useParams();

  const [event, setEvent] = useState<EventType | null>(null);

  const [isLoading, setLoading] = useState(params.id !== "new");

  useEffect(() => {
    if (params.id !== "new") {
      fetchEvent();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  const fetchEvent = async () => {
    try {
      const aboutInformation = await axiosInstance.get(
        apis.events + "/" + params.id
      );

      setEvent(aboutInformation.data as EventType);
    } catch (error) {
      if (error instanceof AxiosError) {
        toast.error(error.response?.data.message, {
          position: "top-right",
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          theme: "light",
        });

        console.log(error);
      }
    } finally {
      setLoading(false);
    }
  };

  if (isLoading) return <GlobalLoader />;

  return (
    <>
      <EventForm event={event} />
    </>
  );
};

export default Event;
