import { string, object, InferType } from "yup";

export const heroSchema = object({
  smallQuote: string().required(),
  title: string().required(),
  description: string().required(),
  picture: string(),
});

export type HeroSchema = InferType<typeof heroSchema>;
