import { Stack, Toolbar, LinearProgress, Typography } from "@mui/material";

interface IGlobalLoaderProps {
  hiddenSpace?: boolean;
  text?: string | null;
}

const GlobalLoader = ({
  hiddenSpace = false,
  text = null,
}: IGlobalLoaderProps) => (
  <>
    <Stack width="100%" left={0} height="100%" position="fixed" top="0">
      {!hiddenSpace && <Toolbar />}

      <LinearProgress />
    </Stack>

    {text && (
      <Stack
        width="100%"
        height="100%"
        position="absolute"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="h5" fontWeight={300}>{text}</Typography>
      </Stack>
    )}
  </>
);

export default GlobalLoader;
