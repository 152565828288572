import { useEffect, useState } from "react";
import { Booking } from "../../types/Booking";
import { axiosInstance } from "../../axios/instance";
import { apis } from "../../axios/apis";
import translation from "../../constants/translations.json";
import {
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Box,
  TableBody,
  Checkbox,
} from "@mui/material";
import GlobalLoader from "../../components/GlobalLoader";
import { toast } from "react-toastify";

const Bookig = () => {
  const [isLoading, setLoading] = useState(true);
  const [booking, setBooking] = useState<Booking[]>([]);
  const [appliedLoading, setAppliedLoading] = useState<number | false>(false);

  useEffect(() => {
    fetchBooking();
  }, []);

  const fetchBooking = async () => {
    try {
      const { data } = await axiosInstance.get(apis.booking);

      setBooking(data as Booking[]);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleApplied = async (id: string, value: boolean, index: number) => {
    try {
      setAppliedLoading(index);

      await axiosInstance.post(apis.booking + "/" + id, {
        applied: value,
      });

      toast.success(translation.messages.dataUpdated, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    } catch (error) {
      console.log(error);
    } finally {
      setAppliedLoading(false);
    }
  };

  if (isLoading) return <GlobalLoader />;

  const renderRows = booking.map((item, index) => (
    <TableRow
      hover={true}
      style={{ cursor: "pointer", opacity: item.applied ? 0.6 : 1 }}
      key={item._id}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell align="left">{item.name}</TableCell>
      <TableCell align="left">{item.phoneNumber}</TableCell>
      <TableCell align="right">
        <Checkbox
          defaultChecked={item.applied}
          disabled={appliedLoading === index}
          onChange={(e, value) => handleApplied(item._id, value, index)}
        />
      </TableCell>
    </TableRow>
  ));

  return (
    <>
      <Box
        marginTop={3}
        marginBottom={4}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography lineHeight={0} margin={0} component="div" variant="h5">
          {translation.booking.resultCount}: {booking.length}
        </Typography>
      </Box>

      {booking.length === 0 ? (
        <Typography variant="h5" textAlign="center" fontWeight={300}>
          {translation.booking.empty}
        </Typography>
      ) : (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  {translation.booking.table.name}
                </TableCell>
                <TableCell align="left">
                  {translation.booking.table.phoneNumber}
                </TableCell>
                <TableCell align="right">
                  {translation.booking.table.applied}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderRows}</TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default Bookig;
