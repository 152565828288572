import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { New as NewType } from "../../types/New";
import { apis } from "../../axios/apis";
import { axiosInstance } from "../../axios/instance";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import GlobalLoader from "../../components/GlobalLoader";
import NewForm from "../../forms/new/new";

const New = () => {
  const params = useParams();
  const [article, setArticle] = useState<NewType | null>(null);
  const [isLoading, setLoading] = useState(params.id !== "new");

  useEffect(() => {
    if (params.id !== "new") {
      fetchNew();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  const fetchNew = async () => {
    try {
      const newInformation = await axiosInstance.get(
        apis.news + "/" + params.id
      );

      setArticle(newInformation.data as NewType);
    } catch (error) {
      if (error instanceof AxiosError) {
        toast.error(error.response?.data.message, {
          position: "top-right",
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          theme: "light",
        });

        console.log(error);
      }
    } finally {
      setLoading(false);
    }
  };

  if (isLoading) return <GlobalLoader />;

  console.log(article, "article");

  return (
    <>
      <NewForm article={article} />
    </>
  );
};

export default New;
