const ENTRY_POINT_DASHBOARD = "/dashboard";

export const routes = {
  login: "/",

  dashboard: ENTRY_POINT_DASHBOARD,
  hero: `${ENTRY_POINT_DASHBOARD}/hero`,
  about: `${ENTRY_POINT_DASHBOARD}/about`,

  events: `${ENTRY_POINT_DASHBOARD}/events`,
  event: `${ENTRY_POINT_DASHBOARD}/event/:id`,

  news: `${ENTRY_POINT_DASHBOARD}/news`,
  new: `${ENTRY_POINT_DASHBOARD}/new/:id`,

  medias: `${ENTRY_POINT_DASHBOARD}/medias`,

  media: `${ENTRY_POINT_DASHBOARD}/media`,

  booking: `${ENTRY_POINT_DASHBOARD}/booking`,
};
