export const apis = {
  auth: "/api/auth",
  hero: "/api/hero",
  events: "/api/events",
  news: "/api/news",
  about: "/api/about",
  booking: "/api/booking",
  media: "/api/media",
  protectedResourse: "/api/auth/protected-resourse",
};
