import {
  Box,
  Button,
  Divider,
  FormHelperText,
  Grid,
  Modal,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import translation from "../../constants/translations.json";
import { useFormik } from "formik";
import { NewSchema, newSchema } from "./new.schema";
import { New } from "../../types/New";
import { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { axiosInstance } from "../../axios/instance";
import { apis } from "../../axios/apis";
import { routes } from "../../constants/routes";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "white",
  borderRadius: "10px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const NewForm = ({ article }: { article: New | null }) => {
  const [isLoading, setLoading] = useState(false);
  const [isOpenModal, setOpenModal] = useState(false);
  const [picture, setPicture] = useState<File | null>(null);
  const navigate = useNavigate();

  const onSubmit = async (values: NewSchema) => {
    try {
      setLoading(true);

      const formData = new FormData();

      Object.keys(values).forEach((key) => {
        formData.append(key, (values as { [key: string]: string })[key]);
      });

      if (picture) formData.append("picture", picture);

      if (article?._id) {
        await axiosInstance.put(apis.news + "/" + article?._id, formData);
      } else {
        const result = await axiosInstance.post(apis.news, formData);

        navigate(routes.new.replace(":id", result.data.id));
      }

      toast.success(translation.messages.dataUpdated, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.data) {
          return toast.error(error.response?.data.message, {
            position: "top-right",
            autoClose: false,
            closeOnClick: true,
            draggable: true,
            theme: "light",
          });
        }

        return toast.error(error.message, {
          position: "top-right",
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          theme: "light",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteEvent = async (id: string) => {
    try {
      await axiosInstance.delete(apis.news + "/" + id);

      navigate(routes.news);

      toast.success(translation.messages.deleteSuccessful, {
        position: "top-right",
        autoClose: false,
        closeOnClick: true,
        draggable: true,
        theme: "light",
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.data) {
          return toast.error(error.response?.data.message, {
            position: "top-right",
            autoClose: false,
            closeOnClick: true,
            draggable: true,
            theme: "light",
          });
        }

        return toast.error(error.message, {
          position: "top-right",
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          theme: "light",
        });
      }
    }
  };

  const formik = useFormik<NewSchema>({
    initialValues: {
      title: article?.title || "",
      shortDescription: article?.shortDescription || "",
      content: article?.content || "",
    },
    validationSchema: newSchema,
    onSubmit,
  });

  const handleFileInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
    formik.setFieldError("picture", undefined);

    const files = (e.target as HTMLInputElement).files;

    if (!files) return;

    const getFile = files[0];
    const getFileExt = getFile.name.split(".")[1];

    if (!["png", "jpg", "jpeg"].includes(getFileExt))
      return formik.setFieldError(
        "picture",
        translation.messages.notSupportedFileFormat
      );

    setPicture(files[0]);
  };

  const renderPromptDelete = (
    <Modal
      open={isOpenModal}
      onClose={() => setOpenModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {translation.confirmDeleteModal.title}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {translation.confirmDeleteModal.description}
        </Typography>
        <Box
          display="flex"
          justifyContent="space-between"
          gap={2}
          marginTop={3}
        >
          <Button
            size="large"
            color="info"
            variant="outlined"
            onClick={() => setOpenModal(false)}
          >
            {translation.cancel}
          </Button>
          <Button
            size="large"
            color="error"
            variant="outlined"
            onClick={() => handleDeleteEvent(article?._id || "")}
          >
            {translation.apply}
          </Button>
        </Box>
      </Box>
    </Modal>
  );

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const pictureUrl =
    (picture && URL.createObjectURL(picture)) ||
    (article?.pictureUrl
      ? process.env.REACT_APP_SERVER_URL + article?.pictureUrl
      : "/default-placeholder.png");

  return (
    <>
      {renderPromptDelete}
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        <Grid item xs={6}>
          <Stack marginBottom={3}>
            <Typography marginBottom={2} variant="subtitle1" fontWeight={400}>
              {translation.information}
            </Typography>
            <Divider />
          </Stack>
          <Stack marginBottom={2}>
            <TextField
              value={formik.values.title}
              onChange={formik.handleChange}
              helperText={formik.errors.title}
              error={Boolean(formik.errors.title)}
              name="title"
              label={translation.title}
              fullWidth
            />
          </Stack>
          <Stack marginBottom={2}>
            <TextField
              value={formik.values.shortDescription}
              onChange={formik.handleChange}
              helperText={formik.errors.shortDescription}
              error={Boolean(formik.errors.shortDescription)}
              name="shortDescription"
              id="outlined-multiline-flexible"
              label={translation.shortDescription}
              multiline
              maxRows={4}
            />
          </Stack>
          <Stack marginBottom={2}>
            <ReactQuill
              value={formik.values.content}
              onChange={(value) => formik.setFieldValue("content", value)}
              theme="snow"
            />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack marginBottom={3}>
            <Typography marginBottom={2} variant="subtitle1" fontWeight={400}>
              {translation.picture}
            </Typography>
            <Divider />
          </Stack>
          <Stack marginBottom={3}>
            <Stack marginBottom={2}>
              <img
                style={{ width: "100%", objectFit: "cover", height: "300px" }}
                src={pictureUrl}
                alt={pictureUrl}
                loading="lazy"
              />
            </Stack>
            <Stack marginBottom={1}>
              <Button
                component="label"
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
              >
                {translation.uploadFile}
                <VisuallyHiddenInput
                  onChange={(e) => handleFileInputChange(e)}
                  type="file"
                />
              </Button>
            </Stack>
            {formik.errors.picture && (
              <FormHelperText error={true}>
                {formik.errors.picture}
              </FormHelperText>
            )}
          </Stack>
        </Grid>
      </Grid>
      <Box position="fixed" bottom={30} right={40} display="flex" gap={2}>
        {article?._id && (
          <Button
            disabled={isLoading}
            size="large"
            color="error"
            variant="outlined"
            onClick={() => setOpenModal(true)}
          >
            {translation.deletet}
          </Button>
        )}

        <Button
          disabled={isLoading}
          size="large"
          color="info"
          variant="contained"
          onClick={() => formik.handleSubmit()}
        >
          {translation.save}
        </Button>
      </Box>
    </>
  );
};

export default NewForm;
