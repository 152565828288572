import { Grid, Container, Typography } from "@mui/material";
import LoginForm from "../../forms/login/login.form";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../../constants/routes";
import { apis } from "../../axios/apis";
import GlobalLoader from "../../components/GlobalLoader";
import { axiosInstance } from "../../axios/instance";

const Login = () => {
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    checkAuthorization();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkAuthorization = async () => {
    try {
      await axiosInstance.get(apis.protectedResourse);

      navigate(routes.dashboard);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  if (isLoading)
    return (
      <GlobalLoader hiddenSpace={true} text="Check Protected Resourse..." />
    );

  return (
    <Container component="main" maxWidth="xs">
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: "100vh" }}
      >
        <Grid item xs={3} width="100%">
          <Typography fontWeight={200} variant="h4" margin="normal">
            Drumcover.kr.ua
          </Typography>
          <Typography
            fontWeight={300}
            color="grey"
            variant="subtitle1"
            marginBottom={3}
          >
            Use email and password for login to system
          </Typography>
          <LoginForm />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Login;
