import {
  Box,
  Button,
  Divider,
  FormHelperText,
  Grid,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import translation from "../../constants/translations.json";
import { AboutInformation } from "../../types/AboutInformation";
import { AboutSchema, aboutSchema } from "./about.schema";
import { useFormik } from "formik";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { ChangeEvent, useState } from "react";
import { axiosInstance } from "../../axios/instance";
import { apis } from "../../axios/apis";
import { toast } from "react-toastify";
import { AxiosError } from "axios";

const AboutForm = ({
  aboutInformation,
}: {
  aboutInformation: AboutInformation | null;
}) => {
  const [isLoading, setLoading] = useState(false);
  const [picture, setPicture] = useState<File | null>(null);

  const onSubmit = async (values: AboutSchema) => {
    try {
      setLoading(true);

      const formData = new FormData();

      Object.keys(values).forEach((key) => {
        formData.append(key, (values as { [key: string]: string })[key]);
      });

      if (picture) formData.append("picture", picture);

      await axiosInstance.post(apis.about, formData);

      toast.success(translation.messages.dataUpdated, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });

      setLoading(false);
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.data) {
          return toast.error(error.response?.data.message, {
            position: "top-right",
            autoClose: false,
            closeOnClick: true,
            draggable: true,
            theme: "light",
          });
        }

        return toast.error(error.message, {
          position: "top-right",
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          theme: "light",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleFileInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
    formik.setFieldError("picture", undefined);

    const files = (e.target as HTMLInputElement).files;

    if (!files) return;

    const getFile = files[0];
    const getFileExt = getFile.name.split(".")[1];

    if (!["png", "jpg", "jpeg"].includes(getFileExt))
      return formik.setFieldError(
        "picture",
        translation.messages.notSupportedFileFormat
      );

    setPicture(files[0]);
  };

  const formik = useFormik<AboutSchema>({
    initialValues: {
      title: aboutInformation?.title || "",
      description: aboutInformation?.description || "",
      youtubeLink: aboutInformation?.youtubeLink || "",
      telegramLink: aboutInformation?.telegramLink || "",
      instagramLink: aboutInformation?.instagramLink || "",
      facebookLink: aboutInformation?.facebookLink || "",
      address: aboutInformation?.address || "",
    },
    validationSchema: aboutSchema,
    onSubmit,
  });

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const pictureUrl =
    (picture && URL.createObjectURL(picture)) ||
    (aboutInformation?.pictureUrl
      ? process.env.REACT_APP_SERVER_URL + aboutInformation?.pictureUrl
      : "/default-placeholder.png");

  return (
    <>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        <Grid item xs={6}>
          <Stack marginBottom={3}>
            <Typography marginBottom={2} variant="subtitle1" fontWeight={400}>
              {translation.information}
            </Typography>
            <Divider />
          </Stack>
          <Stack marginBottom={2}>
            <TextField
              value={formik.values.title}
              onChange={formik.handleChange}
              helperText={formik.errors.title}
              error={Boolean(formik.errors.title)}
              name="title"
              label={translation.title}
              fullWidth
            />
          </Stack>
          <Stack marginBottom={2}>
            <TextField
              value={formik.values.description}
              onChange={formik.handleChange}
              helperText={formik.errors.description}
              error={Boolean(formik.errors.description)}
              name="description"
              id="outlined-multiline-flexible"
              label={translation.description}
              multiline
              maxRows={4}
            />
          </Stack>
          <Stack marginBottom={2}>
            <TextField
              value={formik.values.address}
              onChange={formik.handleChange}
              helperText={formik.errors.address}
              error={Boolean(formik.errors.address)}
              name="address"
              id="outlined-multiline-flexible"
              label={translation.about.address}
              multiline
              maxRows={4}
            />
          </Stack>
          <Stack marginBottom={3}>
            <Typography marginBottom={2} variant="subtitle1" fontWeight={400}>
              {translation.about.scialLinks}
            </Typography>
            <Divider />
          </Stack>
          <Stack marginBottom={2}>
            <TextField
              value={formik.values.youtubeLink}
              onChange={formik.handleChange}
              helperText={formik.errors.youtubeLink}
              error={Boolean(formik.errors.youtubeLink)}
              name="youtubeLink"
              id="outlined-multiline-flexible"
              label={translation.about.youtubeLink}
              multiline
              maxRows={4}
            />
          </Stack>
          <Stack marginBottom={2}>
            <TextField
              value={formik.values.facebookLink}
              onChange={formik.handleChange}
              helperText={formik.errors.facebookLink}
              error={Boolean(formik.errors.facebookLink)}
              name="facebookLink"
              id="outlined-multiline-flexible"
              label={translation.about.facebookLink}
              multiline
              maxRows={4}
            />
          </Stack>
          <Stack marginBottom={2}>
            <TextField
              value={formik.values.telegramLink}
              onChange={formik.handleChange}
              helperText={formik.errors.telegramLink}
              error={Boolean(formik.errors.telegramLink)}
              name="telegramLink"
              id="outlined-multiline-flexible"
              label={translation.about.telegramLink}
              multiline
              maxRows={4}
            />
          </Stack>
          <Stack marginBottom={2}>
            <TextField
              value={formik.values.instagramLink}
              onChange={formik.handleChange}
              helperText={formik.errors.instagramLink}
              error={Boolean(formik.errors.instagramLink)}
              name="instagramLink"
              id="outlined-multiline-flexible"
              label={translation.about.instagramLink}
              multiline
              maxRows={4}
            />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack marginBottom={3}>
            <Typography marginBottom={2} variant="subtitle1" fontWeight={400}>
              {translation.picture}
            </Typography>
            <Divider />
          </Stack>
          <Stack marginBottom={3}>
            <Stack marginBottom={2}>
              <img
                style={{ width: "100%", objectFit: "cover", height: "300px" }}
                src={pictureUrl}
                alt={pictureUrl}
                loading="lazy"
              />
            </Stack>
            <Stack marginBottom={1}>
              <Button
                component="label"
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
              >
                {translation.uploadFile}
                <VisuallyHiddenInput
                  onChange={(e) => handleFileInputChange(e)}
                  type="file"
                />
              </Button>
            </Stack>
            {formik.errors.picture && (
              <FormHelperText error={true}>
                {formik.errors.picture}
              </FormHelperText>
            )}
          </Stack>
        </Grid>
      </Grid>
      <Box position="fixed" bottom={30} right={40}>
        <Button
          disabled={isLoading}
          size="large"
          color="info"
          variant="contained"
          onClick={() => formik.handleSubmit()}
        >
          {translation.save}
        </Button>
      </Box>
    </>
  );
};

export default AboutForm;
