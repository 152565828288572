import { string, object, InferType } from "yup";

export const aboutSchema = object({
  title: string().required(),
  description: string().required(),
  picture: string(),
  address: string(),
  telegramLink: string(),
  youtubeLink: string(),
  facebookLink: string(),
  instagramLink: string(),
});

export type AboutSchema = InferType<typeof aboutSchema>;
