import { createBrowserRouter } from "react-router-dom";
import Login from "../pages/login/login";
import RootRouter from "./root";
import { routes } from "../constants/routes";
import Hero from "../pages/hero/hero";
import About from "../pages/about/about";
import Events from "../pages/events/events";
import Event from "../pages/event/event";
import News from "../pages/news/news";
import New from "../pages/new/new";
import Medias from "../pages/media/media";
import Bookig from "../pages/booking/booking";

export const router = createBrowserRouter([
  {
    path: routes.login,
    element: <Login />,
  },
  {
    element: <RootRouter />,
    children: [
      {
        path: routes.dashboard,
        element: "Dashboard",
      },
      {
        path: routes.hero,
        element: <Hero />,
      },
      {
        path: routes.about,
        element: <About />,
      },
      {
        path: routes.events,
        element: <Events />,
      },
      {
        path: routes.event,
        element: <Event />,
      },

      {
        path: routes.news,
        element: <News />,
      },

      {
        path: routes.medias,
        element: <Medias />,
      },

      {
        path: routes.booking,
        element: <Bookig />,
      },

      {
        path: routes.new,
        element: <New />,
      },
    ],
  },
]);
