import { useEffect, useState } from "react";
import GlobalLoader from "../../components/GlobalLoader";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import translation from "../../constants/translations.json";
import { axiosInstance } from "../../axios/instance";
import { apis } from "../../axios/apis";
import { Event } from "../../types/Event";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Button,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { routes } from "../../constants/routes";

const Events = () => {
  const [isLoading, setLoading] = useState(true);
  const [events, setEvents] = useState<Event[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      const events = await axiosInstance.get(apis.events);

      setEvents(events.data as Event[]);
    } catch (error) {
      if (error instanceof AxiosError) {
        toast.error(error.response?.data.message, {
          position: "top-right",
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          theme: "light",
        });

        console.log(error);
      }
    } finally {
      setLoading(false);
    }
  };

  if (isLoading) return <GlobalLoader />;

  const gotoEvent = (id: string) => navigate(routes.event.replace(":id", id));

  return (
    <>
      <Box
        marginBottom={3}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography lineHeight={0} margin={0} component="div" variant="h5">
          {translation.events.resultCount}: {events.length}
        </Typography>
        <Button onClick={() => gotoEvent("new")} variant="contained">
          {translation.add}
        </Button>
      </Box>

      {events.length === 0 ? (
        <Typography variant="h5" textAlign="center" fontWeight={300}>
          {translation.events.empty}
        </Typography>
      ) : (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  {translation.events.table.title}
                </TableCell>
                <TableCell align="left">
                  {translation.events.table.shortDescription}
                </TableCell>
                <TableCell align="left">
                  {translation.events.table.startDateEvent}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {events.map((event) => (
                <TableRow
                  onClick={() => gotoEvent(event._id)}
                  hover={true}
                  style={{ cursor: "pointer" }}
                  key={event._id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="left">{event.title}</TableCell>
                  <TableCell align="left">{event.shortDescription}</TableCell>
                  <TableCell align="left">{event.startEventDate}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default Events;
