import { useEffect, useState } from "react";
import GlobalLoader from "../../components/GlobalLoader";
import { AboutInformation } from "../../types/AboutInformation";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { axiosInstance } from "../../axios/instance";
import { apis } from "../../axios/apis";
import AboutForm from "../../forms/about/about.form";
import { Stack, Tabs, Tab, Divider } from "@mui/material";

const About = () => {
  const [isLoading, setLoading] = useState(true);
  const [aboutInformation, setAboutInformation] =
    useState<AboutInformation | null>(null);

  useEffect(() => {
    fetchAboutInformation();
  }, []);

  const fetchAboutInformation = async () => {
    try {
      const aboutInformation = await axiosInstance.get(apis.about);

      setAboutInformation(aboutInformation.data as AboutInformation);
    } catch (error) {
      if (error instanceof AxiosError) {
        toast.error(error.response?.data.message, {
          position: "top-right",
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          theme: "light",
        });

        console.log(error);
      }
    } finally {
      setLoading(false);
    }
  };

  if (isLoading) return <GlobalLoader />;

  console.log(aboutInformation, "aboutInformation");

  return (
    <>
      <Stack marginBottom={4}>
        <Tabs value={"2"} aria-label="basic tabs example">
          <Tab label="English" value="1" />
          <Tab label="Українська" value="2" />
        </Tabs>
        <Divider />
      </Stack>
      <AboutForm aboutInformation={aboutInformation} />
    </>
  );
};

export default About;
