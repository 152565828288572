import {
  Modal,
  Typography,
  Divider,
  Tabs,
  Tab,
  Box,
  Stack,
  FormHelperText,
  styled,
  Button,
  TextField,
} from "@mui/material";
import translations from "../../constants/translations.json";
import { ChangeEvent, useState } from "react";
import { useFormik } from "formik";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { MediaSchema, mediaSchema } from "./media.schema";
import { youtubeParserLink } from "../../helpers/youtubeParser";
import { axiosInstance } from "../../axios/instance";
import { apis } from "../../axios/apis";
import { toast } from "react-toastify";
import { AxiosError } from "axios";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "white",
  borderRadius: "10px",
  boxShadow: 24,
};

const MediaModal = ({
  isOpenModal,
  setOpenModal,
  onSuccess,
}: {
  isOpenModal: boolean;
  setOpenModal: (value: boolean) => void;
  onSuccess: () => void;
}) => {
  const [isLoading, setLoading] = useState(false);
  const [picture, setPicture] = useState<File | null>(null);

  const onSubmit = async (values: MediaSchema) => {
    try {
      setLoading(true);

      const formData = new FormData();

      if (values.url)
        formData.append(
          "url",
          values.type === "youtube" ? youtubeParserLink(values.url) : values.url
        );

      formData.append("type", values.type);
      if (picture) formData.append("file", picture);

      await axiosInstance.post(apis.media, formData);

      toast.success(translations.messages.dataUpdated, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });

      handleReset();
      onSuccess();
      setLoading(false);
      setOpenModal(false);
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.data) {
          return toast.error(error.response?.data.message, {
            position: "top-right",
            autoClose: false,
            closeOnClick: true,
            draggable: true,
            theme: "light",
          });
        }

        return toast.error(error.message, {
          position: "top-right",
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          theme: "light",
        });
      }
    }
  };

  const formik = useFormik<MediaSchema>({
    initialValues: {
      type: "picture",
      url: "",
    },
    validationSchema: mediaSchema,
    onSubmit,
  });

  const handleReset = () => {
    setPicture(null);
    formik.resetForm();
  };

  const handleFileInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
    formik.setFieldError("picture", undefined);

    const files = (e.target as HTMLInputElement).files;

    if (!files) return;

    const getFile = files[0];
    const getFileExt = getFile.name.split(".")[1];

    if (!["png", "jpg", "jpeg"].includes(getFileExt))
      return formik.setFieldError(
        "picture",
        translations.messages.notSupportedFileFormat
      );

    setPicture(files[0]);
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const pictureUrl =
    (picture && URL.createObjectURL(picture)) || "/default-placeholder.png";

  const renderPictureTab = (
    <Stack>
      <Stack marginBottom={2}>
        <img
          style={{ width: "100%", objectFit: "cover", height: "200px" }}
          src={pictureUrl}
          alt={pictureUrl}
          loading="lazy"
        />
      </Stack>
      <Stack marginBottom={1}>
        <Button
          component="label"
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}
        >
          {translations.uploadFile}
          <VisuallyHiddenInput
            onChange={(e) => handleFileInputChange(e)}
            type="file"
          />
        </Button>
      </Stack>
      {formik.errors.picture && (
        <FormHelperText error={true}>{formik.errors.picture}</FormHelperText>
      )}
    </Stack>
  );

  const renderYoutubeForm = (
    <Stack marginBottom={1}>
      {formik.values.url && !formik.errors.url && (
        <Stack marginBottom={2}>
          <iframe
            width="100%"
            height="200"
            src={youtubeParserLink(formik.values.url)}
            title="GrooveClix: More Than a Metronome"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </Stack>
      )}
      <TextField
        value={formik.values.url}
        onChange={formik.handleChange}
        helperText={formik.errors.url}
        error={Boolean(formik.errors.url)}
        name="url"
        label={translations.linkYoutube}
        fullWidth
      />
    </Stack>
  );

  return (
    <Modal
      open={isOpenModal}
      onClose={() => setOpenModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box padding={2}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {translations.addMediaModal.title}
          </Typography>
        </Box>
        <Divider />
        <Box>
          <Tabs
            onChange={(e, value) => formik.setFieldValue("type", value)}
            value={formik.values.type}
            aria-label="Media type"
          >
            <Tab label={translations.picture} value="picture" />
            <Tab label={translations.youtube} value="youtube" />
          </Tabs>
          <Divider />
        </Box>
        {formik.values.type === "picture" && (
          <Box padding={2}>{renderPictureTab}</Box>
        )}
        {formik.values.type === "youtube" && (
          <Box padding={2}>{renderYoutubeForm}</Box>
        )}
        <Box display="flex" justifyContent="space-between" gap={2} padding={2}>
          <Button
            onClick={() => {
              setOpenModal(false);
              handleReset();
            }}
            color="error"
            disabled={isLoading}
          >
            {translations.cancel}
          </Button>
          <Button
            disabled={isLoading}
            onClick={() => formik.handleSubmit()}
            variant="contained"
            color="primary"
          >
            {translations.save}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default MediaModal;
