import { useEffect, useState } from "react";
import HeroForm from "../../forms/hero/hero.form";
import GlobalLoader from "../../components/GlobalLoader";
import { axiosInstance } from "../../axios/instance";
import { apis } from "../../axios/apis";
import { HeroInformation } from "../../types/HeroInformation";
import { Stack, Tabs, Tab, Divider } from "@mui/material";

const Hero = () => {
  const [isLoading, setLoading] = useState(true);
  const [heroInformation, setHeroInformation] =
    useState<HeroInformation | null>(null);

  useEffect(() => {
    fetchHeroInformation();
  }, []);

  const fetchHeroInformation = async () => {
    try {
      const heroInformation = await axiosInstance.get(apis.hero);

      setHeroInformation(heroInformation.data as HeroInformation);
    } catch (error) {
      // TODO: add tostify
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  if (isLoading) return <GlobalLoader />;

  return (
    <>
      <Stack marginBottom={4}>
        <Tabs value={"2"} aria-label="basic tabs example">
          <Tab label="English" value="1" />
          <Tab label="Українська" value="2" />
        </Tabs>
        <Divider />
      </Stack>
      <HeroForm heroInformation={heroInformation} />
    </>
  );
};

export default Hero;
