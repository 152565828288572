import { string, object, InferType } from "yup";

export const eventSchema = object({
  title: string().required(),
  picture: string(),
  shortDescription: string().required(),
  content: string().required(),
  startEventDate: string().required(),
});

export type EventSchema = InferType<typeof eventSchema>;
