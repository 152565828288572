import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import translations from "../constants/translations.json";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import AddIcCallOutlinedIcon from "@mui/icons-material/AddIcCallOutlined";
import SpaceDashboardOutlinedIcon from "@mui/icons-material/SpaceDashboardOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import NewspaperOutlinedIcon from "@mui/icons-material/NewspaperOutlined";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import PermMediaOutlinedIcon from "@mui/icons-material/PermMediaOutlined";
import AdjustIcon from "@mui/icons-material/Adjust";

import { routes } from "../constants/routes";
import { Badge, Button, Stack } from "@mui/material";
import { Booking } from "../types/Booking";
import { axiosInstance } from "../axios/instance";
import { apis } from "../axios/apis";
import GlobalLoader from "../components/GlobalLoader";

const drawerWidth = 240;

export default function RootRoute() {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const [isLoading, setLoading] = React.useState(true);
  const [booking, setBooking] = React.useState<Booking[]>([]);
  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    fetchBooking();
  }, []);

  const fetchBooking = async () => {
    try {
      const { data } = await axiosInstance.get(apis.booking);

      setBooking(data as Booking[]);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const menu = [
    {
      label: translations.menu.dashboard,
      icon: <SpaceDashboardOutlinedIcon />,
      path: routes.dashboard,
    },
    {
      label: translations.menu.hero,
      icon: <AdjustIcon />,
      path: routes.hero,
    },
    {
      label: translations.menu.about,
      icon: <InfoOutlinedIcon />,
      path: routes.about,
    },
    {
      label: translations.menu.news,
      icon: <NewspaperOutlinedIcon />,
      path: routes.news,
    },
    {
      label: translations.menu.events,
      icon: <EventNoteOutlinedIcon />,
      path: routes.events,
    },
    {
      label: translations.menu.media,
      icon: <PermMediaOutlinedIcon />,
      path: routes.medias,
    },
  ];

  if (isLoading) return <GlobalLoader text="Отримання даних..." hiddenSpace />;

  const bookingCount = booking.filter(item => !item.applied).length;
  
  const drawer = (
    <div>
      <Toolbar>
        <Typography variant="h6" noWrap component="div">
          Drumcover Panel
        </Typography>
      </Toolbar>
      <Divider />
      <List>
        <Link
          to={routes.booking}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <ListItem disablePadding>
            <ListItemButton selected={routes.booking === location.pathname}>
              <ListItemIcon>
                <Badge badgeContent={bookingCount} color="primary">
                  <AddIcCallOutlinedIcon />
                </Badge>
              </ListItemIcon>
              <ListItemText primary={translations.menu.booking} />
            </ListItemButton>
          </ListItem>
        </Link>
        <Divider />
        {menu.map((item, index) => (
          <Link
            key={index}
            to={item.path}
            onClick={handleDrawerClose}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <ListItem disablePadding>
              <ListItemButton selected={item.path === location.pathname}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItemButton>
            </ListItem>
          </Link>
        ))}
      </List>
    </div>
  );

  const onLogout = async () => {
    navigate(routes.login);
  };

  console.log(location, 'location');
  

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <Badge badgeContent={bookingCount} color="info">
              <MenuIcon />
            </Badge>
          </IconButton>
          <Stack
            width="100%"
            flexDirection="row"
            display="flex"
            justifyContent="space-between"
          >
            <Typography variant="h6" noWrap component="div">
              
            </Typography>
            <Button variant="outlined" color="inherit" onClick={onLogout}>
              {translations.logout}
            </Button>
          </Stack>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        position="relative"
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
}
