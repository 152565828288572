import { string, object, InferType } from "yup";

export const newSchema = object({
  title: string().required(),
  picture: string(),
  shortDescription: string().required(),
  content: string().required(),
});

export type NewSchema = InferType<typeof newSchema>;
