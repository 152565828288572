import { useFormik } from "formik";
import { LoginSchema, loginSchema } from "./login.schema";
import { useState } from "react";
import { Alert, Button, Stack, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { routes } from "../../constants/routes";
import { axiosInstance } from "../../axios/instance";
import { apis } from "../../axios/apis";

import { AxiosError } from "axios";

const LoginForm = () => {
  const [isLoading, setLoading] = useState(false);
  const [globalError, setGlobalError] = useState<string | null>(null);
  const navigate = useNavigate();

  const onSubmit = async (values: LoginSchema) => {
    try {
      setGlobalError(null);
      setLoading(true);

      await axiosInstance.post(apis.auth, values);

      navigate(routes.dashboard);
    } catch (error) {
      setLoading(false);

      if (error instanceof AxiosError) {
        if (error.response?.data) {
          return setGlobalError(error.response?.data.message);
        }

        return setGlobalError(error.message);
      }

      console.log(error);
    }
  };

  const formik = useFormik<LoginSchema>({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit,
  });

  return (
    <>
      <Stack marginBottom={2}>
        <TextField
          value={formik.values.email}
          onChange={formik.handleChange}
          name="email"
          helperText={formik.errors.email}
          error={Boolean(formik.errors.email)}
          label="Email"
          fullWidth
        />
      </Stack>
      <Stack marginBottom={2}>
        <TextField
          value={formik.values.password}
          onChange={formik.handleChange}
          name="password"
          helperText={formik.errors.password}
          error={Boolean(formik.errors.password)}
          label="Password"
          type="password"
          fullWidth
        />
      </Stack>
      {globalError && (
        <Stack marginBottom={2} marginTop={2}>
          <Alert severity="error">{globalError}</Alert>
        </Stack>
      )}
      <Button
        disabled={isLoading}
        onClick={() => formik.handleSubmit()}
        variant="contained"
        fullWidth
        size="large"
      >
        Login
      </Button>
    </>
  );
};

export default LoginForm;
