import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Modal,
  Typography,
} from "@mui/material";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import GlobalLoader from "../../components/GlobalLoader";
import { Media } from "../../types/Media";
import { axiosInstance } from "../../axios/instance";
import { apis } from "../../axios/apis";
import translations from "../../constants/translations.json";
import { youtubeParserLink } from "../../helpers/youtubeParser";
import MediaModal from "./mediaModal";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "white",
  borderRadius: "10px",
  boxShadow: 24,
};

const Medias = () => {
  const [isLoading, setLoading] = useState(true);
  const [isOpenModal, setOpenModal] = useState(false);
  const [isOpenDeleteModal, setOpenDeleteModal] = useState<string | null>(null);
  const [medias, setMedias] = useState<Media[]>([]);

  useEffect(() => {
    fetchMedias();
  }, []);

  const handleDeleteMedia = async () => {
    try {
      await axiosInstance.delete(apis.media + "/" + isOpenDeleteModal);

      fetchMedias();

      setOpenDeleteModal(null);

      toast.success(translations.messages.deleteSuccessful, {
        position: "top-right",
        autoClose: false,
        closeOnClick: true,
        draggable: true,
        theme: "light",
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.data) {
          return toast.error(error.response?.data.message, {
            position: "top-right",
            autoClose: false,
            closeOnClick: true,
            draggable: true,
            theme: "light",
          });
        }

        return toast.error(error.message, {
          position: "top-right",
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          theme: "light",
        });
      }
    }
  };

  const fetchMedias = async () => {
    try {
      const { data } = await axiosInstance.get(apis.media);

      setMedias(data as Media[]);
    } catch (error) {
      if (error instanceof AxiosError) {
        toast.error(error.response?.data.message, {
          position: "top-right",
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          theme: "light",
        });

        console.log(error);
      }
    } finally {
      setLoading(false);
    }
  };

  if (isLoading) return <GlobalLoader />;

  const renderMedia = medias.map((media) => (
    <Grid item xs={4} sm={4} md={4} key={media._id}>
      <Card key={media._id}>
        <CardContent>
          {media.type === "youtube" ? (
            <iframe
              width="100%"
              height="200"
              src={youtubeParserLink(media.url)}
              title="GrooveClix: More Than a Metronome"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          ) : (
            <img
              width="100%"
              height="200"
              alt={media.url}
              src={process.env.REACT_APP_SERVER_URL + media.url}
            />
          )}
          <Typography variant="caption">
            {translations.mediaType}:{" "}
            <Typography color={media.type === "youtube" ? "red" : "blue"}>
              {media.type}
            </Typography>
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            color="error"
            size="small"
            onClick={() => setOpenDeleteModal(media._id)}
          >
            {translations.deletet}
          </Button>
        </CardActions>
      </Card>
    </Grid>
  ));

  const renderPromptDelete = (
    <Modal
      open={Boolean(isOpenDeleteModal)}
      onClose={() => setOpenDeleteModal(null)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box padding={2}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {translations.confirmDeleteModal.title}
          </Typography>
        </Box>
        <Divider />
        <Box padding={2}>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {translations.confirmDeleteModal.description}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" gap={2} padding={2}>
          <Button
            size="large"
            color="info"
            variant="outlined"
            onClick={() => setOpenDeleteModal(null)}
          >
            {translations.cancel}
          </Button>
          <Button
            size="large"
            color="error"
            variant="outlined"
            onClick={() => handleDeleteMedia()}
          >
            {translations.apply}
          </Button>
        </Box>
      </Box>
    </Modal>
  );

  const addMediaModal = (
    <MediaModal
      onSuccess={fetchMedias}
      isOpenModal={isOpenModal}
      setOpenModal={setOpenModal}
    />
  );

  return (
    <>
      {addMediaModal}
      {renderPromptDelete}
      <Box
        marginBottom={3}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography fontWeight={400} variant="h6">
          {translations.mediaCount} : {medias.length}
        </Typography>
        <Button onClick={() => setOpenModal(true)} variant="contained">
          {translations.addMedia}
        </Button>
      </Box>
      <Grid
        container
        rowSpacing={1}
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        {renderMedia}
      </Grid>
    </>
  );
};

export default Medias;
